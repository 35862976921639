import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  IconButton,
  Alert,
  Chip,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link, useNavigate } from "react-router-dom";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import capitalizarCadena from "../../utils/Cadena";
import ModalUpdateBillGeneral from "./modalUpdateBillGeneral";
import ModalDeleteBillGeneral from "./deletebillgeneral";
import { getStatusStyles } from "../../utils/getStyles";
import ModalBillsAsociate from "./billsNotAsociate";
import { TapsHeaders } from "../../components/tapsHeaders";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import { fetchClients, fetchInvoices } from "../incomeGeneral/fetch";
import { fetchCenter } from "../diary/fetch";
import { fetchCenterOn } from "../incomeGeneral/fetch";
import DateCusmton from "../../utils/DateCusmton";
import ModalNewBillGeneral from "./modalNewBillGenral";
import { ModalStatus } from "./modalStatus";
import { getUniqueYears } from "../../utils/DateCusmton";
import { fetchOccupation } from "../../utils/profesion";

export default function IncomeInfo() {
  const [photo_profile, setPhoto_profile] = useState();
  const [openModalNewBill, setOpenModalNewBill] = useState(false);
  const [openModalNoAsociate, setOpenModalNoAsociate] = useState(false);
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // permite nuevos campos para tarifa nueva (post)
  const [openModalUpdateBill, setOpenModalUpdateBill] = useState(false); // abrir modal de actualizar factura
  const [invoices, setInvoices] = useState([]);
  const [centers, setCenters] = useState();
  const [clients, setClients] = useState();
  const [idupdate, setIdupdate] = useState();
  const [mappedOptions, setMappedOptions] = useState([]);
  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [centersfiltred, setCentersfiltred] = useState();
  const [occupation, setOccupation] = useState();
  const [mainDataFilters, setMainDataFilters] = useState({
    name_center: "",
    month: "",
    clientId: "",
    year: "",
  });

  // get de foto del profesional
  useEffect(() => {
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
    fetchClients(loadApi, setClients, showSnack);
    fetchOccupation(loadApi, showSnack, setOccupation);
    fetchInvoices(setOpenbackd, loadApi, setInvoices, showSnack);
    fetchCenter(setCenters, showSnack, setOpenbackd, loadApi);
    fetchCenterOn(setCentersfiltred, showSnack, loadApi);
  }, []);

  const uniqueYears = getUniqueYears(invoices);
  const [filteredData2, setFilteredData2] = useState([]);
  const filteredData = invoices.filter((item) => {
    const formattedDate = new Date(item.date);
    const itemMonth = formattedDate.getUTCMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
    const itemYear = formattedDate.getUTCFullYear();

    const selectedMonth = Number(mainDataFilters.month);
    const selectedYear = Number(mainDataFilters.year);

    const monthMatches =
      mainDataFilters.month === "" || itemMonth === selectedMonth;
    const yearMatches =
      mainDataFilters.year === "" || itemYear === selectedYear;
    const centerMatches =
      mainDataFilters.name_center === "" ||
      item.center_name === mainDataFilters.name_center;
    const clientMatches =
      mainDataFilters.clientId === "" ||
      item.client_id === mainDataFilters.clientId;

    return monthMatches && yearMatches && centerMatches && clientMatches;
  });

  useEffect(() => {
    setFilteredData2(filteredData);
  }, [invoices, mainDataFilters, fetch]);

  const clearFilter = () => {
    setMainDataFilters({
      name_center: "",
      month: "",
      clientId: "",
      year: "",
    });
  };

  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: "",
    name_rate: "",
    amount: "",
    date: new Date().toISOString().split("T")[0],
    status: "Pendiente",
    method: "",
    term: "",
  });
  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    method: false,
  });

  const [mainDataNewRate, setMainDataNewRate] = useState({
    name: "",
    concurrence: "",
    price: "",
    term: "",
  });

  const [mainDataNewRateError, setMainDataNewRateError] = useState({
    name: false,
    concurrence: false,
    price: false,
  });

  const handleCloseModal = () => {
    setOpenModalNewBill(false);
    setMainDataNewBill({
      center_id: "",
      client_id: "",
      amount: "",
      date: "",
      status: "Pendiente",
      method: "",
      term: "",
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
      term: "",
    });
    setShowAdditionalFields(false);
  };

  const [mainDataUpdateBill, setMainDataUpdateBill] = useState({
    center_id: "",
    client_id: "",
    name_rate: "",
    amount: "",
    date: "",
    status: "",
    term: "",
    method: "",
  });
  const [UpdateBillError, setUpdateBillError] = useState({
    client_id: false,
    name_rate: false,
    amount: false,
    date: false,
    status: false,
    term: false,
    method: false,
  });

  const statuses = ["cobrado", "pendiente", "atrasado"];

  const handleCloseModalUpdate = async () => {
    setOpenModalUpdateBill(false);
    setMainDataUpdateBill({
      center_id: "",
      client_id: "",
      amount: "",
      date: "",
      status: "",
      term: "",
      method: "",
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
      term: "",
    });
    setShowAdditionalFields(false);
  };

  const SubmitmodalBill = async (e) => {
    e.preventDefault();

    if (showAdditionalFields === false) {
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {});

      loadApi(`rate/${mainDataNewBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {
          setOpenbackd(false);
        });
    }
  };

  const SubmitmodalBillUpdate = async (e) => {
    e.preventDefault();
    if (showAdditionalFields === false) {
      if (!ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError))
        return;
      setOpenModalUpdateBill(false);
      setOpenbackd(true);
      //falta endpoint de actualizar ingreso
      loadApi(`invoice/update_invoice/${idupdate}`, true, "put", {
        ...mainDataUpdateBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setInvoices((adata) =>
            adata.filter((aitem) => aitem.id !== idupdate)
          );
          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.Invoice];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModalUpdate();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError))
        return;
      setOpenModalUpdateBill(false);
      setOpenbackd(true);

      loadApi(`invoice/update_invoice/${idupdate}`, true, "put", {
        ...mainDataUpdateBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setInvoices((adata) =>
            adata.filter((aitem) => aitem.id !== idupdate)
          );
          setInvoices((adata) => {
            const newInvoices = [...adata, response.data.Invoice];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModalUpdate();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {});

      loadApi(`rate/${mainDataUpdateBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally(() => {
          setOpenbackd(false);
        });
    }
  };

  // actualizar solo el estado
  const [status, setStatus] = useState("");

  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));
    options.push({ label: `sin ${occupation}`, value: null });
    options.unshift({ label: `Todos los ${occupation}`, value: "" });
    setMappedOptions(options);
  }, [clients, fetch]);

  const deleteBill = (id) => {
    setOpenbackd(true);
    loadApi(`invoice/delete/${id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success", 3000);
        //eliminar por id del
        setInvoices((adata) => adata.filter((aitem) => aitem.id !== id));
      })
      .catch((e) => {
        showSnack(e.message);
      })
      .finally(() => {
        setOpenbackd(false);
      });
  };

  const menu = [
    { label: "Grafico ingresos", path: `/income` },
    { label: "Grafico cobros", path: `/collections` },
    { label: "Detalle", path: `/income/info` },
  ];

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (row, isChecked) => {
    if (isChecked) {
      // Añadir fila seleccionada
      setSelectedRows((prevSelected) => [...prevSelected, row]);
    } else {
      // Eliminar fila deseleccionada
      setSelectedRows((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== row.id)
      );
    }
  };

  // Función para manejar el checkbox general
  const handleSelectAll = (checked) => {
    // Filtrar solo las filas que no están deshabilitadas (isNotCobrado)
    const enabledRows = filteredData2.filter((row) => row.status !== "Cobrado");

    if (checked) {
      // Si se selecciona el checkbox general, añadir todas las filas habilitadas
      setSelectedRows(enabledRows);
    } else {
      // Si se deselecciona, limpiar la selección
      setSelectedRows([]);
    }
  };

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="xl"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          /*  backgroundColor: "red", */
          p: 2,
          minHeight: 600,
        }}
      >
        <TapsHeaders menu={menu} clientid={0} namecomplete={"Cobros Generales"}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center", // Centra los botones horizontalmente
              flexWrap: "wrap", // Permite que los botones se envuelvan en pantallas pequeñas
              gap: "16px", // Añade un espacio entre los botones
              marginTop: { md: "18%" }, // Espaciado inferior
            }}
          >
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setOpenModalNewBill(true);
              }}
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
                m: { xs: 1, md: 0 }, // Espaciado inferior en pantallas pequeñas
                width: { xs: "100%", md: "auto" }, // Ancho completo en pantallas pequeñas
              }}
            >
              <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />
              <Typography variant="p1" sx={{ color: "black" }}>
                Nuevo Ingreso
              </Typography>
            </Button>

            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setOpenModalNoAsociate(true);
              }}
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
                m: { xs: 1, md: 0 }, // Espaciado inferior en pantallas pequeñas
                width: { xs: "100%", md: "auto" }, // Ancho completo en pantallas pequeñas
              }}
            >
              <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />
              <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
                Actualizar
              </Typography>
            </Button>
          </Box>
        </TapsHeaders>

        <Grid container direction="row" marginTop={3} spacing={1}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.name_center}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      name_center: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los centros...</MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      // recorrer centros row.id sera value y se mostrara row.name
                      <MenuItem key={index} value={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, md: 40 } }}>
                Mes:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={mainDataFilters.month}
                  onChange={({ target }) =>
                    setMainDataFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1, minWidth: { xs: 55, md: 40 } }}>
                Año:
              </Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Autocomplete
                  size="small"
                  value={mainDataFilters.year}
                  onChange={(event, newValue) =>
                    setMainDataFilters({ ...mainDataFilters, year: newValue })
                  }
                  options={["", ...uniqueYears]} // Incluye opción para "Todos los años"
                  getOptionLabel={(option) =>
                    option === "" ? "Todos los años" : option.toString()
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>{occupation}:</Typography>

              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", lg: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option.label}
                value={
                  mappedOptions.find(
                    (option) => option.value === mainDataFilters.clientId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setMainDataFilters((prevFilters) => ({
                    ...prevFilters,
                    clientId: newValue ? newValue.value : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: 100 }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                  onClick={clearFilter}
                >
                  Limpiar Filtros
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 2, mx: 2, mt: 1 }} />

        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 2,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={0.5}
              lg={0.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Checkbox
                indeterminate={
                  selectedRows.length > 0 &&
                  selectedRows.length <
                    filteredData2.filter((row) => row.status !== "Cobrado")
                      .length
                }
                checked={
                  selectedRows.length ===
                  filteredData2.filter((row) => row.status !== "Cobrado").length
                }
                onChange={(e) => handleSelectAll(e.target.checked)}
                color="primary"
              />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.2}
              lg={1.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "80%" }}>
                <Typography variant="body1">Fecha</Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Centro</Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={1.4}
              lg={1.4}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Concepto de la Tarifa</Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">{occupation}</Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.2}
              lg={1.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "80%" }}>
                <Typography variant="body1">Valor</Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1.6}
              lg={1.6}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "80%" }}>
                <Typography variant="body1" noWrap>
                  Comentario
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Estado
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Acciones
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {filteredData2?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">No hay facturas.</Alert>
          </Stack>
        )}
        {filteredData2 &&
          (filteredData2 || []).map((row, index) => {
            const isSinPaciente =
              !row?.client || row?.client === `Sin ${occupation}`;
            const isNotCobrado = row?.status !== "Cobrado";
            return (
              <Box
                key={index}
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: 2,
                  borderRadius: 1,
                  my: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={0.5}
                    lg={0.5}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Checkbox
                      disabled={!isNotCobrado}
                      checked={selectedRows.some((r) => r.id === row.id)}
                      onChange={(e) =>
                        handleCheckboxChange(row, e.target.checked)
                      }
                      color="primary"
                    />
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.2}
                    lg={1.2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "80%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Fecha
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        {DateCusmton(row?.date)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      Centro
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {capitalizarCadena(
                        row?.center_name !== null
                          ? row?.center_name
                          : "centro eliminado"
                      )}
                    </Typography>
                  </Grid>

                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.4}
                    lg={1.4}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "85%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Tarifa
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        sx={{
                          whiteSpace: "nowrap", // Evita que el texto haga saltos de línea
                          overflow: "hidden", // Oculta el contenido que se desborda
                          textOverflow: "ellipsis", // Agrega los puntos suspensivos si el texto es demasiado largo
                        }}
                      >
                        {row?.name_rate}
                      </Typography>
                    </Box>
                  </Grid>

                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" } }}
                    >
                      {occupation}
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {!!row.client
                        ? capitalizarCadena(row?.client)
                        : `Sin ${occupation}`}
                    </Typography>
                  </Grid>

                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.2}
                    lg={1.2}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "80%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Valor
                      </Typography>
                      <Typography variant="body1" fontWeight={400}>
                        $ {FormatearNumeroConSeparador(row?.amount)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.6}
                    lg={1.6}
                    align="center"
                    sx={{ px: "0!important" }}
                  >
                    <Box sx={{ maxWidth: "80%" }}>
                      <Typography
                        variant="body1"
                        sx={{ display: { md: "none" } }}
                      >
                        Comentario
                      </Typography>
                      <Typography variant="body1" noWrap fontWeight={400}>
                        {row?.method ? row.method : "sin comentarios"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    align="center"
                    fontWeight={400}
                    sx={{ px: "0!important" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ display: { md: "none" }, mx: 2 }}
                    >
                      Estado
                    </Typography>

                    <ModalStatus
                      row={row}
                      open={openModalStatus}
                      onClose={setOpenModalStatus}
                      showSnack={showSnack}
                      filteredData2={filteredData2}
                      setFilteredData2={setFilteredData2}
                    />
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    align="center"
                    sx={{
                      flexWrap: "wrap",
                      display: "flex",
                      justifyContent: { xs: "center", md: "space-between" },
                      gap: 0.5, // Añadir espacio entre los iconos
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      title="Editar"
                      size="large"
                      color="secondary"
                      sx={{
                        bgcolor: "#aed6f1",
                        ":hover": {
                          bgcolor: "#C1CEE8!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModalUpdateBill({
                          id: row?.id,
                          center_id: row?.center_id,
                          client_id: row?.client_id,
                          center_name: row?.center_name,
                          name_rate: row?.name_rate,
                          amount: FormatearNumeroConSeparador(row?.amount),
                          status: row?.status,
                          date: row?.date,
                          method: row?.method,
                          term: row?.term,
                        });
                        setIdupdate(row?.id);
                      }}
                    >
                      <CreateOutlinedIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      size="large"
                      title="Eliminar"
                      color="secondary"
                      sx={{
                        bgcolor: "#f5b7b1",
                        ":hover": {
                          bgcolor: "#DEBFBF",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => {
                        setOpenModaldelete(row.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalNewBillGeneral
        openModalNewBill={openModalNewBill}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        SubmitmodalBill={SubmitmodalBill}
        loadApi={loadApi}
        newBillError={newBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clients={clients}
        occupation={occupation}
      />
      <ModalUpdateBillGeneral
        openModalUpdateBill={openModalUpdateBill}
        handleCloseModalUpdate={handleCloseModalUpdate}
        centers={centers}
        mainDataUpdateBill={mainDataUpdateBill}
        setMainDataUpdateBill={setMainDataUpdateBill}
        SubmitmodalBillUpdate={SubmitmodalBillUpdate}
        loadApi={loadApi}
        UpdateBillError={UpdateBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clients={clients}
      />
      <ModalDeleteBillGeneral
        setOpenModaldelete={setOpenModaldelete}
        openModaldelete={openModaldelete}
        deleteBill={deleteBill}
      />

      <ModalBillsAsociate
        showSnack={showSnack}
        setOpenbackd={setOpenbackd}
        loadApi={loadApi}
        openModalNoAsociate={openModalNoAsociate}
        setOpenModalNoAsociate={setOpenModalNoAsociate}
        setFetch={setFetch}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setFilteredData2={setFilteredData2}
      />
    </>
  );
}

function ValidateModalNewBill(mainDataNewBill, setNewBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataNewBill.center_id,
    name_rate: !mainDataNewBill.name_rate,
    // amount: !mainDataNewBill.amount,
    date: !mainDataNewBill.date,
    status: !mainDataNewBill.status,
  };

  setNewBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}

function ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError) {
  var final = false;
  const newAdata = {
    name: !mainDataNewRate.name,
    concurrence: !mainDataNewRate.concurrence,
    price: !mainDataNewRate.price,
  };

  setMainDataNewRateError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}

function ValidateModalUpdateBill(mainDataUpdateBill, setUpdateBillError) {
  var final = false;
  const newAdata = {
    center_id: !mainDataUpdateBill.center_id,
    name_rate: !mainDataUpdateBill.name_rate,
    amount: !mainDataUpdateBill.amount,
    date: !mainDataUpdateBill.date,
    status: !mainDataUpdateBill.status,
    term: !mainDataUpdateBill.term,
  };

  setUpdateBillError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
