import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  IconButton,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import { TapsHeaders } from "../../components/tapsHeaders";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalHeader from "../../components/header";
import { useState, useEffect } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import { ModalRegister } from "./modalRegister";
import { getSubUsers } from "./fetch";
import { ModalDelete } from "../../components/modalDelete";
import { ShowModulesUser } from "./showModules";
import { deleteSubUser } from "./fetch";
import { ModalEdit } from "./modalEdit";

export function RegisterProfesional() {
  const [photo_profile, setPhoto_profile] = useState();
  const { loadApi, loadingApi } = useApi();
  const [openbackd, setOpenbackd] = useState(false);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [users, setUsers] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModal, setOpenModal] = useState({ state: false, user: null });

  useEffect(() => {
    getSubUsers(loadApi, showSnack, setUsers);
  }, []);

  const handleDelete = (itemId) => {
    setIdToDelete(itemId);
    setOpenModalDelete(true);
  };

  const confirmDelete = () => {
    setUsers(users.filter((item) => item.id !== idToDelete));
    setOpenModalDelete(false);
    deleteSubUser(loadApi, showSnack, setUsers, idToDelete, users);
  };

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
        }}
      >
        <ModalRegister setUsers={setUsers} users={users} />

        <Box
          sx={{
            border: "1px solid #e0e0e0",
            padding: 1,
            borderRadius: 1,
            my: 1,
            display: { xs: "none", sm: "none", md: "inherit" },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={2.2}
              lg={2.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Nombre completo</Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={1.8}
              lg={1.8}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1">Cedula</Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={2.5}
              lg={2.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Box sx={{ maxWidth: "85%" }}>
                <Typography variant="body1">Correo</Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={1.2}
              lg={1.2}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                estado
              </Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              lg={1.5}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                modulos
              </Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{ mt: 2 }} />

            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              align="center"
              sx={{ px: "0!important" }}
            >
              <Typography variant="body1" noWrap>
                Acciones
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {users?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">No hay profesionales registrados.</Alert>
          </Stack>
        )}

        {users &&
          (users || [])?.map((row, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #e0e0e0",
                padding: 2,
                borderRadius: 1,
                my: 1,
                overflow: "hidden",
              }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                <Grid item xs={12} sm={12} md={2.2} lg={2.2}>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      textOverflow: "ellipsis",
                      textAlign: { xs: "left", md: "center" },
                      px: { xs: 1, md: 0 },
                    }}
                  >
                    {row?.name} {row?.lastname}
                  </Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" }, mt: 2 }}
                />

                <Grid item xs={12} sm={12} md={1.8} lg={1.8}>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      textOverflow: "ellipsis",
                      textAlign: { xs: "left", md: "center" },
                      px: { xs: 1, md: 0 },
                    }}
                  >
                    {row.document}
                  </Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" }, mt: 2 }}
                />

                <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      textOverflow: "ellipsis",
                      textAlign: { xs: "left", md: "center" },
                      px: { xs: 1, md: 0 },
                    }}
                  >
                    {row.username}
                  </Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" }, mt: 2 }}
                />

                <Grid item xs={12} sm={12} md={1.2} lg={1.2}>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      textOverflow: "ellipsis",
                      textAlign: { xs: "left", md: "center" },
                      px: { xs: 1, md: 0 },
                    }}
                  >
                    {row.status}
                  </Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" }, mt: 2 }}
                />

                <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
                  <Box
                    sx={{
                      textAlign: { xs: "left", md: "center" },
                      px: { xs: 1, md: 0 },
                    }}
                  >
                    <ShowModulesUser modules={row.modules} user={row.name} />
                  </Box>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" }, mt: 2 }}
                />

                <Grid item xs={12} sm={12} md={1.6} lg={1.6}>
                  <Box
                    sx={{
                      textAlign: { xs: "left", md: "center" },
                      px: { xs: 1, md: 0 },
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      size="large"
                      title="Editar"
                      color="secondary"
                      sx={{
                        mx: 1,
                        bgcolor: "#aed6f1",
                        ":hover": {
                          bgcolor: "#C1CEE8!important",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => setOpenModal({ state: true, user: row })}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      title="Eliminar"
                      size="large"
                      color="secondary"
                      sx={{
                        bgcolor: "#f5b7b1",
                        ":hover": {
                          bgcolor: "#DEBFBF",
                          boxShadow:
                            "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                        },
                      }}
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Container>

      <Backdrop
        open={openBackdrop}
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalDelete
        openModalDelete={openModalDelete}
        setDeleteBool={confirmDelete}
        title={"¿Está seguro de eliminar este registro?"}
        setOpenModalDelete={setOpenModalDelete}
      />

      <ModalEdit
        openModal={openModal}
        loadApi={loadApi}
        setOpenModal={setOpenModal}
        setUsers={setUsers}
        showSnack={showSnack}
        users={users}
        user={openModal?.user}
      />

      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
