import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Alert,
  Paper,
  InputAdornment,
} from "@mui/material";

import GlobalHeaderAdmin from "../../components/headerAdmin";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import { write, utils } from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import capitalizarCadena from "../../utils/Cadena";
import dateToInput from "../../utils/DateToInput";
import DateCusmton from "../../utils/DateCusmton";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import { isFullWidth } from "validator";
import { fetchOccupation } from "../../utils/profesion";
export default function ProfessionalOverview() {
  const [photo_profile, setPhoto_profile] = useState();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState({
    searchText: "",
  });
  const [occupations, setOccupations] = useState();
  useEffect(() => {
    fetchOccupation(loadApi, showSnack, setOccupations);
    setOpenbackd(true);
    loadApi("admin/info", true, "get")
      .then((response) => {
        setUsers(response.data.professionals);
        setOpenbackd(false);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);

  const filteredUsers = users.filter((user) => {
    const searchTextLower = searchText.searchText.toLowerCase();
    const userNameLower = user?.name.toLowerCase();
    const userLastNameLower = user?.lastname.toLowerCase();
    const id = user?.id.toString();
    const occupation = user?.occupation.toLowerCase();
    const phone = user?.phone.toString();
    const email = user?.email.toLowerCase();

    return (
      userNameLower.includes(searchTextLower) ||
      userLastNameLower.includes(searchTextLower) ||
      id.includes(searchTextLower) ||
      occupation.includes(searchTextLower) ||
      phone.includes(searchTextLower) ||
      email.includes(searchTextLower)
    );
  });

  return (
    <>
      <GlobalHeaderAdmin />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
          minHeight: 600,
        }}
      >
        {" "}
        <Grid container direction="row" sx={{ mb: 2 }} spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} container alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: 600, color: "black" }}>
              Usuarios
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" justifyContent="flex-end">
              <TextField
                id="outlined-basic"
                // Eliminar el label
                fullWidth
                width={{ sm: "none" }}
                label=""
                placeholder="Buscar"
                variant="outlined"
                value={searchText?.searchText}
                onChange={(event) =>
                  setSearchText((adata) => ({
                    ...adata,
                    searchText: event.target.value,
                  }))
                }
                sx={{ width: { xs: "100%", sm: "auto" } }}
                InputProps={{
                  sx: {
                    borderRadius: 3,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />
        <TableContainer component={Paper} sx={{ mt: { xs: 1, sm: 3, md: 5 } }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Nombre </TableCell>
                <TableCell align="center"> Id</TableCell>
                <TableCell align="center">Profesión</TableCell>
                <TableCell align="center">Centros registrados</TableCell>
                <TableCell align="center">{occupations} registrados</TableCell>
                <TableCell align="center">Teléfono</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Fecha de primer ingreso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* array que genera luego de filtrarse cambiar  */}
              {filteredUsers &&
                (filteredUsers || [])?.map((row, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {capitalizarCadena(`${row?.name} ${row?.lastname}`)}
                    </TableCell>
                    <TableCell align="center">{row?.id}</TableCell>
                    <TableCell align="center">{row?.occupation}</TableCell>
                    <TableCell align="center">
                      {row?.centrosCount !== 0
                        ? row?.centrosCount
                        : "Sin centro"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.clientesCount !== 0
                        ? row?.clientesCount
                        : "Sin Pacientes"}
                    </TableCell>
                    <TableCell align="center">{row?.phone}</TableCell>
                    <TableCell align="center">{row?.email}</TableCell>
                    <TableCell align="center">
                      {DateCusmton(row?.createdAt)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredUsers?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">Sin usuarios.</Alert>
          </Stack>
        )}
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
