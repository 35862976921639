import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormGroup,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Cookies from "js-cookie";
import { useState } from "react";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import Logo from "../../images/Logoazul.svg";
import GoogleLoginCC from "./GoogleButton";
import { useNavigate } from "react-router-dom";
import GlobalHeader from "../../components/header";
import { useAuth } from "../../hooks/useAuth";
import { Phone } from "@mui/icons-material";
import { onCLS } from "web-vitals";
import { Terminos } from "./terminos";
import { useEffect } from "react";

function MuiContent() {
  const { loadApi, loadingApi } = useApi();
  const { setLoggedAuth, setUserAuth } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password2, setPassword2] = useState();
  const [openModalTerm, setOpenModalTerm] = useState(false);
  const [accept, setAccept] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [openbackd, setOpenbackd] = useState(false);
  const handlerChangeContra2 = (e) => {
    setPassword2(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [mainData, setMainData] = useState({
    name: "",
    lastname: "",
    document: "",
    username: "",
    phone: "",
    occupation: "",
    password: "",
  });

  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();

  const [errorName, setErrorName] = useState({
    error: false,
    Message: "",
  });

  const [errorNational_id, setErrorNational_id] = useState({
    error: false,
    Message: "",
  });

  const [errorEmail, setErrorEmail] = useState({
    errorEmail: false,
    Message: "",
  });

  const [errorPassword, setErrorPassword] = useState({
    errorP: false,
    Message: "",
  });
  const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  async function loadInitialData() {
    const [response1] = await Promise.all([
      await loadApi("web/professions", false, "get"),
    ]);

    setProfessions(response1.data);
    setOpenbackd(false);
  }

  useEffect(() => {
    setOpenbackd(true);
    loadInitialData()
      .catch((e) => {
        showSnack(e.message);
      })
      .finally((e) => {});
  }, []);

  const Register = async (e) => {
    e.preventDefault();
    let erremail, errNid, errpass;
    if (validateEmail(mainData.username)) {
      setErrorEmail({
        errorEmail: false,
        Message: "", //correo valido
      });
      erremail = false;
    } else {
      setErrorEmail({
        errorEmail: true,
        Message: "Email incorrecto", //correo valido
      });
      erremail = true;
    }
    if (mainData.password === password2) {
      setErrorPassword({
        errorP: false,
        Message: "",
      });
      errpass = false;
      if (mainData.password.length < 8) {
        setErrorPassword({
          errorP: true,
          Message: "La contraseña es menor a 8 caracteres",
        });
        errpass = true;
      } else {
        setErrorPassword({
          errorP: false,
          Message: "",
        });
        errpass = false;
      }
    } else {
      setErrorPassword({
        errorP: true,
        Message: "la contraseña no coinciden",
      });
      errpass = true;
    }
    if (mainData.document.length < 6) {
      setErrorNational_id({
        error: true,
        Message: "Error cedula es menor a 6 caracteres",
      });
      errNid = true;
    } else {
      setErrorNational_id({
        error: false,
        Message: "",
      });
      errNid = false;
    }

    if (erremail === false && errNid === false && errpass === false) {
      loadApi("register", false, "post", {
        ...mainData,
      })
        .then((response) => {
          //showSnack(response.data.message, "success", 2000);

          setTimeout(() => {
            navigate("/prev_login");
          }, 1000);
        })
        .catch((e) => {
          showSnack(e.message);
        });
    }
  };

  const label = { inputProps: { "aria-label": "Terminos y condiciones" } };

  return (
    <>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GlobalHeader />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "calc(100vh - 80px)",

          padding: {
            xs: 4,
            sm: 0,
            lg: 4,
            xl: 0,
          },
          my: 3,
        }}
      >
        <Stack
          width="100%"
          sx={{
            animation: "entryX 0.5s ease forwards",
            maxWidth: {
              sm: "sm",
              xs: "100%",
            },
          }}
          spacing={2}
          component="form"
          onSubmit={Register}
        >
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="h4">Registro</Typography>
            <img alt="CCorbit" src={Logo} height={70} />
          </Stack>
          <Stack direction="row">
            <Button
              fullWidth
              sx={{
                borderRadius: "10px 0 0 4px",
                bgcolor: "#002B44e6",
                borderRight: "1px solid #a6a6a682",
              }}
              href="/login"
            >
              Iniciar Sesión
            </Button>
            <Button
              fullWidth
              sx={{ borderRadius: "0 10px 4px 0" }}
              href="/register"
            >
              Registro
            </Button>
          </Stack>

          <Divider
            sx={{ height: 2, borderRadius: 2 }}
            variant="fullWidth"
          ></Divider>
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "background.paper",
              padding: "20px",
              boxShadow: "1px 1px 6px #e1e1e1f9",
              borderRadius: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Nombre
                </Typography>

                <TextField
                  id="name" // ID personalizado
                  type="text"
                  variant="outlined"
                  placeholder="Nombre"
                  fullWidth
                  onChange={({ target }) =>
                    setMainData((adata) => ({ ...adata, name: target.value }))
                  }
                  value={mainData?.name}
                  helperText={errorName?.Message}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Apellidos
                </Typography>
                <TextField
                  fullWidth
                  id="lastname" // ID personalizado
                  type="text"
                  variant="outlined"
                  placeholder="Apellidos"
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      lastname: target.value,
                    }))
                  }
                  value={mainData.lastname}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Cedula
                </Typography>
                <TextField
                  fullWidth
                  id="document" // ID personalizado
                  type="number"
                  variant="outlined"
                  placeholder="Numero de Cedula"
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      document: target.value,
                    }))
                  }
                  value={mainData.document}
                  error={errorNational_id.error}
                  helperText={errorNational_id.Message}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Celular
                </Typography>
                <TextField
                  fullWidth
                  id="phone" // ID personalizado
                  type="number"
                  variant="outlined"
                  placeholder="Celular"
                  onChange={({ target }) =>
                    setMainData((adata) => ({ ...adata, phone: target.value }))
                  }
                  value={mainData.phone}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Correo Electrónico
                </Typography>
                <TextField
                  fullWidth
                  id="correo" // ID personalizado
                  placeholder="ejemplo@mail.com"
                  type="email"
                  variant="outlined"
                  required
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      username: target.value,
                    }))
                  }
                  value={mainData.username}
                  error={errorEmail.errorEmail}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Ocupación
                </Typography>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mainData?.occupation}
                  fullWidth
                  required
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      occupation: target.value,
                    }))
                  }
                >
                  {professions.map((profession) => (
                    <MenuItem key={profession} value={profession.name}>
                      {profession.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Contraseña
                </Typography>

                <OutlinedInput
                  id="password" // ID personalizado
                  placeholder="*********"
                  required
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  onChange={({ target }) =>
                    setMainData((adata) => ({
                      ...adata,
                      password: target.value,
                    }))
                  }
                  value={mainData.password}
                  error={errorPassword.errorP}
                  fullWidth
                  helperText={errorPassword.Message}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={errorPassword.errorP}
                  helperText="El nombre no puede estar vacío"
                >
                  {errorPassword.Message}
                </FormHelperText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingLeft: "0!important", paddingRight: 2 }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "black", mb: 1 }}
                >
                  Confirmar contraseña
                </Typography>

                <OutlinedInput
                  required
                  id="contraseña" // ID personalizado
                  placeholder="*********"
                  type={showPassword ? "text" : "password"}
                  value={password2}
                  onChange={handlerChangeContra2}
                  error={errorPassword.errorP}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Stack>

          <FormGroup
            sx={{
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            <FormControlLabel
              sx={{
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
              }}
              control={
                <Checkbox
                  checked={accept}
                  onClick={() => setOpenModalTerm(true)}
                  required
                />
              }
              label="Terminos y condiciones"
            />
          </FormGroup>

          <LoadingButton
            loading={!!loadingApi.includes("post__register")}
            fullWidth
            type="submit"
            sx={{
              py: 1.3,
              fontSize: "1.2rem",
            }}
          >
            Registrarse
          </LoadingButton>
        </Stack>
      </Stack>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Terminos
        openModalTerm={openModalTerm}
        setOpenModalTerm={setOpenModalTerm}
        setAccept={setAccept}
      />
    </>
  );
}

export default MuiContent;
