export function postUserProfessional(
  dataToSend,
  loadApi,
  showSnack,
  setUsers,
  users,
  setOpenModalCreateAppo,
  setMainData,
  onClose,
  setOpenbackd
) {
  setOpenbackd(true);
  loadApi("subUsers/", true, "POST", dataToSend)
    .then((response) => {
      const idUser = response.data?.id;
      showSnack(response.data.message, "success");

      setUsers([...users, { id: idUser, ...dataToSend }]);
      onClose();
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally(() => {
      setOpenbackd(false);
    });
}

export function getModules(loadApi, showSnack, setModule) {
  loadApi("modules", true, "GET")
    .then((response) => {
      setModule(response.data);
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally(() => {});
}

export function getSubUsers(loadApi, showSnack, setUsers) {
  loadApi("subUsers/all_subUsers", true, "GET")
    .then((response) => {
      setUsers(response.data);
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally(() => {});
}

export function deleteSubUser(loadApi, showSnack, setUsers, id, users) {
  loadApi("subUsers/delete/" + id, true, "DELETE")
    .then((response) => {
      showSnack(response.data.message, "success");
      setUsers(users.filter((item) => item.id !== id));
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally(() => {});
}

export function updateSubUser(loadApi, showSnack, setUsers, formData, users) {
  loadApi(`subUsers/update/${formData?.id}`, true, "put", {
    ...formData,
  })
    .then((response) => {
      showSnack(response.data.message, "success");
      const updatedUsers = users.map((item) =>
        item.id === formData.id ? { ...item, ...formData } : item
      );
      setUsers(updatedUsers);
    })
    .catch((e) => {
      showSnack(e.message);
    })
    .finally((e) => {
      //setOpenbackd(false);
    });
}
