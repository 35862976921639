import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  InputAdornment,
  IconButton,
  FormGroup,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Visibility,
  VisibilityOff,
  AddOutlined as AddOutlinedIcon,
} from "@mui/icons-material";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import { postUserProfessional, getModules } from "./fetch";

export function ModalRegister({ setUsers, users }) {
  const [openModalCreateAppo, setOpenModalCreateAppo] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password2, setPassword2] = useState("");
  const [mainData, setMainData] = useState({
    username: "",
    password: "",
    document: "",
    name: "",
    status: "active",
    lastname: "",
    modules: [],
  });

  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [modules, setModules] = useState([]);
  const [openbackd, setOpenbackd] = useState(false);

  const [errorName, setErrorName] = useState({ error: false, Message: "" });
  const [errorNational_id, setErrorNational_id] = useState({
    error: false,
    Message: "",
  });
  const [errorEmail, setErrorEmail] = useState({
    errorEmail: false,
    Message: "",
  });
  const [errorPassword, setErrorPassword] = useState({
    errorP: false,
    Message: "",
  });

  const validateEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handlerChangeContra2 = (e) => setPassword2(e.target.value);

  useEffect(() => {
    getModules(loadApi, showSnack, (modules) => {
      setModules(modules);
      setMainData((prevData) => ({
        ...prevData,
        modules: modules.map((module) => ({
          module_id: module.id, // Usar module_id en lugar de id
          name: module.name,
          can_read: false,
          can_write: false,
        })),
      }));
    });
  }, []);

  const handleToggleModule = (moduleId, permission) => {
    setMainData((prevData) => ({
      ...prevData,
      modules: prevData.modules.map((module) =>
        module.module_id === moduleId
          ? {
              ...module,
              can_read:
                permission === "read" ? !module.can_read : module.can_read,
              can_write:
                permission === "write" ? !module.can_write : module.can_write,
            }
          : module
      ),
    }));
  };
  const onClose = () => {
    setMainData({
      ...mainData,
      username: "",
      password: "",
      document: "",
      name: "",
      status: "active",
      lastname: "",
      modules: modules.map((module) => ({
        module_id: module.id, // Usar module_id en lugar de id
        name: module.name,
        can_read: false,
        can_write: false,
      })),
    });
    setOpenModalCreateAppo(false);
    setErrorNational_id({
      error: false,
      Message: "",
    });
    setErrorEmail({
      errorEmail: false,
      Message: "",
    });
    setErrorPassword({
      errorP: false,
      Message: "",
    });
  };
  const register = async () => {
    let hasErrors = false;

    if (!validateEmail(mainData.username)) {
      setErrorEmail({ errorEmail: true, Message: "Email incorrecto" });
      hasErrors = true;
    } else {
      setErrorEmail({ errorEmail: false, Message: "" });
    }

    if (mainData.password !== password2) {
      setErrorPassword({
        errorP: true,
        Message: "Las contraseñas no coinciden",
      });
      hasErrors = true;
    } else if (mainData.password?.length < 8) {
      setErrorPassword({
        errorP: true,
        Message: "La contraseña es menor a 8 caracteres",
      });
      hasErrors = true;
    } else {
      setErrorPassword({ errorP: false, Message: "" });
    }

    if (mainData.document?.length < 6) {
      setErrorNational_id({
        error: true,
        Message: "Cedula es menor a 6 caracteres",
      });
      hasErrors = true;
    } else {
      setErrorNational_id({ error: false, Message: "" });
    }

    if (!hasErrors) {
      // Filtrar solo los módulos seleccionados
      const selectedModules = mainData.modules.filter(
        (module) => module.can_read || module.can_write
      );

      const dataToSend = { ...mainData, modules: selectedModules };
      postUserProfessional(
        dataToSend,
        loadApi,
        showSnack,
        setUsers,
        users,
        setOpenModalCreateAppo,
        setMainData,
        onClose,
        setOpenbackd
      );
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Typography variant="h5">Registro de usuarios</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModalCreateAppo(true)}
          startIcon={<AddOutlinedIcon />}
        >
          Nuevo Ingreso
        </Button>
      </Box>

      <Dialog
        open={openModalCreateAppo}
        onClose={() => {
          onClose();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Registrar nuevo usuario</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nombre"
                value={mainData.name}
                onChange={(e) =>
                  setMainData({ ...mainData, name: e.target.value })
                }
                error={errorName.error}
                helperText={errorName.Message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Apellidos"
                value={mainData.lastname}
                onChange={(e) =>
                  setMainData({ ...mainData, lastname: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cédula"
                type="number"
                value={mainData.document}
                onChange={(e) =>
                  setMainData({ ...mainData, document: e.target.value })
                }
                error={errorNational_id.error}
                helperText={errorNational_id.Message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Correo Electrónico"
                type="email"
                value={mainData.username}
                onChange={(e) =>
                  setMainData({ ...mainData, username: e.target.value })
                }
                error={errorEmail.errorEmail}
                helperText={errorEmail.Message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                value={mainData.password}
                onChange={(e) =>
                  setMainData({ ...mainData, password: e.target.value })
                }
                error={errorPassword.errorP}
                helperText={errorPassword.Message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Confirmar contraseña"
                type={showPassword ? "text" : "password"}
                value={password2}
                onChange={handlerChangeContra2}
                error={errorPassword.errorP}
                helperText={errorPassword.Message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Seleccionar Módulos y Permisos
                </Typography>
              </Grid>
              {modules.map((module) => (
                <Grid item xs={12} sm={6} md={6} lg={6} key={module.id}>
                  <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="subtitle1">{module.name}</Typography>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              mainData.modules.find(
                                (m) => m.module_id === module.id
                              )?.can_read || false
                            }
                            onChange={() =>
                              handleToggleModule(module.id, "read")
                            }
                          />
                        }
                        label="Lectura"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              mainData.modules.find(
                                (m) => m.module_id === module.id
                              )?.can_write || false
                            }
                            onChange={() =>
                              handleToggleModule(module.id, "write")
                            }
                          />
                        }
                        label="Escritura"
                      />
                    </FormGroup>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <LoadingButton
            loading={loadingApi.includes("post__register")}
            onClick={() => {
              register();
            }}
            color="primary"
            variant="contained"
          >
            Registrar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
