import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import dateToInput from "../../utils/DateToInput";
import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { fetchOccupation } from "../../utils/profesion";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";

export default function ModalUpdateBill({
  openModalUpdateBill,
  handleCloseModalUpdate,
  centers,
  mainDataUpdateBill,
  setMainDataUpdateBill,
  SubmitmodalBillUpdate,
  loadApi,
  UpdateBillError,
  showAdditionalFields,
  setShowAdditionalFields,
  mainDataNewRate,
  setMainDataNewRate,
  mainDataNewRateError,
  clientid,
  setIdupdate,
}) {
  const [rates, setRates] = useState();
  const [newextFieldcreate, setNewextFieldcreate] = useState(false);
  const [occupation, setOccupation] = useState();

  const { msgSB, openSB, showSnack, typeSB } = useSnack();

  useEffect(() => {
    fetchOccupation(loadApi, showSnack, setOccupation);
    setMainDataUpdateBill({
      center_id: openModalUpdateBill.center_id,
      id: openModalUpdateBill.id,
      client_id: clientid,
      name_rate: openModalUpdateBill.name_rate,
      amount: openModalUpdateBill.amount,
      date: openModalUpdateBill.date,
      status: openModalUpdateBill.status,
      method: openModalUpdateBill.method,
      term: openModalUpdateBill.term,
    });
    setIdupdate(openModalUpdateBill.id);
  }, [openModalUpdateBill]);

  // faltta que el enpoint tenga un campo de name_income, tango en get como en el post y update
  useEffect(() => {
    loadApi(`rate/rates/${openModalUpdateBill.center_id}`, true, "get")
      .then((response) => {
        setRates(response.data);
      })
      .catch((e) => {});
  }, [openModalUpdateBill.center_id]);

  useEffect(() => {
    if (showAdditionalFields === true) {
      setNewextFieldcreate(true);
      setMainDataUpdateBill((adata) => ({
        ...adata,
        amount: "",
      }));
    } else {
      setNewextFieldcreate(false);
    }
  }, [showAdditionalFields]);
  useEffect(() => {
    setMainDataUpdateBill((adata) => ({
      ...adata,
      name_rate: mainDataNewRate.name,
      amount: mainDataNewRate.price,
      term: mainDataNewRate.term,
    }));
  }, [mainDataNewRate]);

  const currentRateName =
    mainDataUpdateBill?.name_rate || openModalUpdateBill?.name_rate;
  const rateExists = rates?.some((rate) => rate.name === currentRateName);

  return (
    <>
      <Dialog
        open={!!openModalUpdateBill}
        onClose={handleCloseModalUpdate}
        PaperProps={{
          component: "form",
        }}
        disableScrollLock
      >
        <DialogTitle>Actualizar ingreso</DialogTitle>

        <DialogContent>
          <Box sx={{ width: { md: 500 } }}>
            <Grid
              container
              direction="row"
              spacing={2}
              marginTop={1}
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Centro"
                  value={openModalUpdateBill.center_name}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Fecha "
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  type="date"
                  fullWidth
                  onChange={({ target }) =>
                    setMainDataUpdateBill((adata) => ({
                      ...adata,
                      date: target.value,
                    }))
                  }
                  error={UpdateBillError.date}
                  value={dateToInput(mainDataUpdateBill.date)}
                  required
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth required disabled={newextFieldcreate}>
                  <InputLabel id="demo-simple-select-label">
                    Nombre de la tarifa
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Nombre de la tarifa"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    //debe carmbiar para poner nombre tarifa
                    value={
                      mainDataUpdateBill?.name_rate ||
                      openModalUpdateBill?.name_rate
                    }
                    onChange={({ target }) =>
                      setMainDataUpdateBill((adata) => ({
                        ...adata,
                        name_rate: target.value,
                      }))
                    }
                    error={UpdateBillError.amount}
                    required
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción...
                    </MenuItem>

                    {rates?.length < 1 && (
                      <MenuItem value={""}>
                        <Alert severity="warning">
                          El centro no tiene tarifas.
                        </Alert>
                      </MenuItem>
                    )}
                    {rates &&
                      (rates || []).map((row, index) => (
                        // recorrer centros row.id sera value y se mostrara row.name
                        <MenuItem
                          value={row.name}
                          onClick={() => {
                            setMainDataUpdateBill((adata) => ({
                              ...adata,
                              amount: row.price,
                            }));
                          }}
                        >
                          {row?.name}
                        </MenuItem>
                      ))}
                    {!rateExists && currentRateName && (
                      <MenuItem value={currentRateName}>
                        {currentRateName}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Valor"
                  variant="outlined"
                  type="text"
                  id="amount"
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  required
                  fullWidth
                  //debe carmbiar para poner nombre tarifa
                  value={FormatearNumeroConSeparador(mainDataUpdateBill.amount)}
                  onChange={({ target }) =>
                    setMainDataUpdateBill((adata) => ({
                      ...adata,
                      amount: target.value,
                    }))
                  }
                  error={UpdateBillError.amount}
                  onKeyDown={(event) => {
                    // Permitir sólo números, la tecla de retroceso, la tecla de suprimir, las teclas de flecha izquierda y derecha
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Estado"
                    id="demo-simple-select"
                    sx={{ borderRadius: 2 }}
                    value={
                      mainDataUpdateBill?.status || openModalUpdateBill?.status
                    }
                    onChange={({ target }) =>
                      setMainDataUpdateBill((adata) => ({
                        ...adata,
                        status: target.value,
                      }))
                    }
                    /*  error={UpdateBillError.status} */
                    required
                  >
                    <MenuItem value="Pendiente">Pendiente</MenuItem>
                    <MenuItem value="Cobrado">Cobrado</MenuItem>
                    <MenuItem value="Atrasado">Atrasado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Comentario"
                  multiline
                  fullWidth
                  maxRows={8}
                  rows={2}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={mainDataUpdateBill?.method}
                  onChange={({ target }) =>
                    setMainDataUpdateBill((adata) => ({
                      ...adata,
                      method: target.value,
                    }))
                  }
                  error={UpdateBillError?.method}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={() => {
                    setShowAdditionalFields(true);
                  }}
                  sx={{
                    bgcolor: "secondary.main",
                    color: "primary.contrastText",
                    boxShadow:
                      "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    ":hover": {
                      bgcolor: "#ebebeb",
                      boxShadow:
                        "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                    },
                  }}
                >
                  <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
                  <Typography
                    variant="p1"
                    sx={{ fontWeight: 600, color: "black" }}
                  >
                    Nueva Tarifa
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {showAdditionalFields && (
              <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    label="Nombre de la tarifa"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    onChange={({ target }) =>
                      setMainDataNewRate((adata) => ({
                        ...adata,
                        name: target.value,
                      }))
                    }
                    value={mainDataNewRate.name}
                    error={mainDataNewRateError.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    required
                    label="Valor"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    onChange={(event) => {
                      let val = FormatearNumeroConSeparador(event.target.value);
                      setMainDataNewRate((adata) => ({
                        ...adata,
                        price: val,
                      }));
                    }}
                    value={mainDataNewRate.price}
                    error={mainDataNewRateError.price}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Cadencia
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      sx={{ borderRadius: 2, mb: 1 }}
                      label="cadence"
                      value={mainDataNewRate.concurrence}
                      onChange={({ target }) =>
                        setMainDataNewRate((adata) => ({
                          ...adata,
                          concurrence: target.value,
                        }))
                      }
                      error={mainDataNewRateError.concurrence}
                    >
                      <MenuItem value={"Mensual"}>Mensual</MenuItem>
                      <MenuItem value={"Quincenal"}>Quincenal</MenuItem>
                      <MenuItem value={"Por dia"}>Por dia</MenuItem>
                      <MenuItem value={"Por hora"}>Por hora</MenuItem>
                      <MenuItem value={"Por media hora"}>
                        Por media hora
                      </MenuItem>
                      <MenuItem
                        value={"Por pacientes"}
                      >{`por ${occupation}`}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          <Button
            onClick={handleCloseModalUpdate}
            variant="contained"
            sx={{
              px: 5,
              bgcolor: "#fff",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={SubmitmodalBillUpdate}
            variant="contained"
            sx={{
              px: 5,
              textTransform: "none",
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
