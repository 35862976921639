import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Stack,
  IconButton,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import GlobalHeader from "../../components/header";
import { useEffect, useState } from "react";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link, useNavigate } from "react-router-dom";
import dateToInput from "../../utils/DateToInput";
import ReactECharts from "echarts-for-react";
import ModalNewBillGeneral from "../incomeInfo/modalNewBillGenral";
import FormatearNumeroConSeparador from "../../utils/formatNumber";
import { TapsHeaders } from "../../components/tapsHeaders";
import {
  ValidateModalNewBill,
  ValidateModalNewRate,
} from "../../utils/validateModal";
import { calculateStatusTotal } from "../../utils/calculateStatus";
import { getUniqueYearsFromIncomes } from "../../utils/years";
import { fetchCenterOn, fetchInvoices, fetchClients } from "./fetch";
import { fetchProfile } from "../ExternalChats.jsx/fetch";
import { fetchCenter } from "../diary/fetch";
import {
  monthNames,
  getOption,
  newAmounts,
  newStatus,
} from "../../utils/years";
import { colors } from "../../utils/getStyles";
import { fetchOccupation } from "../../utils/profesion";

export default function IncomeGeneral() {
  const [photo_profile, setPhoto_profile] = useState();
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openbackd, setOpenbackd] = useState(false);
  const [openModalNewBill, setOpenModalNewBill] = useState(false);
  const [mappedOptions, setMappedOptions] = useState([]);
  const [occupation, setOccupation] = useState("");
  const [incomes, setIncomes] = useState([]);
  const [centers, setCenters] = useState([]);
  const [clients, setClients] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // permite nuevos campos para tarifa nueva (post)
  const [amount, setAmount] = useState(newAmounts);
  const [status, setStatus] = useState(newStatus);
  const [centersfiltred, setCentersfiltred] = useState();
  const [uniqueYears, setUniqueYears] = useState([]);
  const [filters, setFilters] = useState({
    centerId: "",
    clientId: "",
    month: "",
    year: "",
  });

  const [statusTotal, setStatusTotal] = useState({
    total: {
      Pendiente: { cantidad: 0, valor: 0 },
      Cobrado: { cantidad: 0, valor: 0 },
      Atrasado: { cantidad: 0, valor: 0 },
    },
  });

  const [newBillError, setNewBillError] = useState({
    center_id: false,
    client_id: false,
    name_rate: false,
    // amount: false,
    date: false,
    status: false,
    method: false,
  });

  const [mainDataNewRate, setMainDataNewRate] = useState({
    name: "",
    concurrence: "",
    price: "",
  });

  const [mainDataNewRateError, setMainDataNewRateError] = useState({
    name: false,
    concurrence: false,
    // price: false,
  });

  const [mainDataNewBill, setMainDataNewBill] = useState({
    center_id: "",
    client_id: "",
    name_rate: "",
    amount: "0",
    date: new Date().toISOString().split("T")[0], // Fecha actual por defecto
    status: "Pendiente",
    method: "",
  });

  useEffect(() => {
    fetchCenterOn(setCentersfiltred, showSnack, loadApi);
    fetchProfile(setPhoto_profile, showSnack, setOpenbackd, loadApi);
    fetchInvoices(setOpenbackd, loadApi, setIncomes, showSnack);
    fetchCenter(setCenters, showSnack, setOpenbackd, loadApi);
    fetchClients(loadApi, setClients, showSnack);
    fetchOccupation(loadApi, showSnack, setOccupation);
  }, []);

  useEffect(() => {
    handleFilter();
  }, [incomes, filters]);

  useEffect(() => {
    // Solo recalcular si el estado de `status` ha cambiado y es válido
    if (status) {
      calculateStatusTotal(setStatusTotal, status);
    }
  }, [status]);

  //-----------------------

  const handleFilter = () => {
    const filtered = incomes.filter((item) => {
      const matchesCenter = filters.centerId
        ? item?.center_id === parseInt(filters.centerId)
        : true;
      const matchesClient = filters.clientId
        ? item?.client_id === parseInt(filters?.clientId)
        : filters?.clientId === ""
        ? true
        : item?.client_id === null;
      const matchesYear = filters.year
        ? new Date(item.date).getFullYear() === parseInt(filters.year)
        : true;
      const matchesMonth = filters?.month
        ? new Date(item.date).getMonth() + 1 === parseInt(filters.month)
        : true;

      return matchesCenter && matchesClient && matchesYear && matchesMonth;
    });

    const updatedAmounts = {
      Enero: 0,
      Febrero: 0,
      Marzo: 0,
      Abril: 0,
      Mayo: 0,
      Junio: 0,
      Julio: 0,
      Agosto: 0,
      Septiembre: 0,
      Octubre: 0,
      Noviembre: 0,
      Diciembre: 0,
    };
    const updatedStatus = {
      Enero: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Febrero: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Marzo: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Abril: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Mayo: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Junio: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Julio: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Agosto: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Septiembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Octubre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Noviembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
      Diciembre: {
        Pendiente: { cantidad: 0, valor: 0 },
        Cobrado: { cantidad: 0, valor: 0 },
        Atrasado: { cantidad: 0, valor: 0 },
      },
    };

    filtered.forEach((item) => {
      const date = new Date(item.date);
      const month = date.getMonth() + 1;
      const monthName = monthNames[month];

      updatedAmounts[monthName] += item.amount;

      updatedStatus[monthName][item.status].cantidad += 1; // Incrementar la cantidad de estado
      updatedStatus[monthName][item.status].valor += item.amount; // Sumar el monto del estado
    });

    setAmount(updatedAmounts);
    setStatus(updatedStatus);
  };

  const handleCloseModal = () => {
    setOpenModalNewBill(false);
    setMainDataNewBill({
      center_id: "",
      client_id: "",
      amount: "",
      date: new Date().toISOString().split("T")[0], // Fecha actual por defecto
      status: "Pendiente",
      method: "",
    });
    setMainDataNewRate({
      name: "",
      concurrence: "",
      price: "",
    });
    setShowAdditionalFields(false);
  };
  const data = {
    legendData: ["Pendiente", "Cobrado", "Atrasado"],
    seriesData: [
      {
        name: "Pendiente",
        value: statusTotal?.total?.Pendiente?.cantidad,
        valor: statusTotal?.total?.Pendiente?.valor,
      },
      {
        name: "Cobrado",
        value: statusTotal?.total?.Cobrado?.cantidad,
        valor: statusTotal?.total?.Cobrado?.valor,
      },
      {
        name: "Atrasado",
        value: statusTotal?.total?.Atrasado?.cantidad,
        valor: statusTotal?.total?.Atrasado?.valor,
      },
    ],
  };

  const option = {
    title: {
      text: "Estado de Ingresos",
      x: "center",
      textStyle: {
        fontSize: 18,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.seriesName} <br/>${
          params.name
        } : ${FormatearNumeroConSeparador(params.value)} (${
          params.percent
        }%)<br/>`;
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 10,
      left: "center",
      data: data.legendData,
    },
    series: [
      {
        name: "Estado",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data.seriesData.map((item) => ({
          name: item.name,
          value: item.valor,
          itemStyle: {
            color: colors[item.name],
          },
        })),
      },
    ],
  };

  useEffect(() => {
    const options = (clients || []).map((item) => ({
      label: `${item.name} ${item.lastname}`,
      value: item.id,
    }));
    options.push({ label: `sin ${occupation}`, value: null });
    options.unshift({ label: `Todos los ${occupation}`, value: "" });
    setMappedOptions(options);
  }, [clients]);

  const SubmitmodalBill = async (e) => {
    e.preventDefault();

    if (showAdditionalFields === false) {
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          const rta = response.data.response.invoices;

          const updatedData = {
            ...rta,
            amount: parseFloat(rta.amount.replace(/,/g, "")), // Eliminar la coma del amount
          };

          setIncomes((adata) => {
            const newInvoices = [...adata, updatedData];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      if (!ValidateModalNewRate(mainDataNewRate, setMainDataNewRateError))
        return;
      if (!ValidateModalNewBill(mainDataNewBill, setNewBillError)) return;
      setOpenModalNewBill(false);
      setOpenbackd(true);

      loadApi("invoice", true, "post", {
        ...mainDataNewBill,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);

          setIncomes((adata) => {
            const newInvoices = [...adata, response.data.response.invoices];
            // Ordenar el array por fecha
            newInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
            return newInvoices;
          });
          handleCloseModal();
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {});

      loadApi(`rate/${mainDataNewBill.center_id}`, true, "post", {
        ...mainDataNewRate,
      })
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
        })
        .catch((e) => {
          showSnack(e.message);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    }
  };

  useEffect(() => {
    if (incomes.length > 0) {
      setUniqueYears(getUniqueYearsFromIncomes(incomes));
    }
  }, []);

  const menu = [
    { label: "Grafico ingresos", path: `/income` },
    { label: "Grafico cobros", path: `/collections` },
    { label: "Detalle", path: `/income/info` },
  ];

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Container
        maxWidth="lg"
        sx={{
          alignItems: "center",
          background: "#FFFFFF",
          p: 2,
        }}
      >
        <TapsHeaders namecomplete="Cobros Generales" menu={menu}>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenModalNewBill(true);
            }}
            sx={{
              marginTop: "17%",
              bgcolor: "secondary.main",
              color: "primary.contrastText",
              boxShadow:
                "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              ":hover": {
                bgcolor: "#ebebeb",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
              },
            }}
          >
            <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />{" "}
            <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
              Nuevo Ingreso
            </Typography>
          </Button>
        </TapsHeaders>

        <Grid container direction="row" marginTop={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Centro:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters.centerId}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      centerId: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los centros...</MenuItem>
                  {centers &&
                    (centers || []).map((row, index) => (
                      // recorrer centros row.id sera value y se mostrara row.name
                      <MenuItem key={index} value={row?.id}>
                        {row?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>{occupation}:</Typography>
              <Autocomplete
                size="small"
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                options={mappedOptions}
                getOptionLabel={(option) => option?.label}
                value={
                  mappedOptions?.find(
                    (option) => option.value === filters.clientId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    clientId: newValue ? newValue.value : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: 100 }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Mes:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ borderRadius: 2 }}
                  value={filters?.month}
                  onChange={({ target }) =>
                    setFilters((adata) => ({
                      ...adata,
                      month: target.value,
                    }))
                  }
                  required
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Todos los meses</MenuItem>
                  <MenuItem value="01">Enero</MenuItem>
                  <MenuItem value="02">Febrero</MenuItem>
                  <MenuItem value="03">Marzo</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Mayo</MenuItem>
                  <MenuItem value="06">Junio</MenuItem>
                  <MenuItem value="07">Julio</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Septiembre</MenuItem>
                  <MenuItem value="10">Octubre</MenuItem>
                  <MenuItem value="11">Noviembre</MenuItem>
                  <MenuItem value="12">Diciembre</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ m: 1 }}>Año:</Typography>
              <FormControl
                sx={{ width: { xs: "70%", sm: "85%", md: "100%" } }}
                required
              >
                <Autocomplete
                  size="small"
                  value={filters.year}
                  onChange={(event, newValue) =>
                    setFilters({ ...filters, year: newValue })
                  }
                  options={["", ...uniqueYears]} // Incluye opción para "Todos los años"
                  getOptionLabel={(option) =>
                    option === "" ? "Todos los años" : option.toString()
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{ mt: { xs: 2, md: 0 }, px: 2 }}
          >
            <Button
              fullWidth
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",

                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
              onClick={() => {
                setFilters({
                  centerId: "",
                  clientId: "",
                  month: "",
                  year: "",
                });
              }}
            >
              Limpiar Filtros
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ m: 2 }} />
        <Box
          sx={{
            overflowX: { xs: "scroll!important", sm: "hidden!important" },
            width: "100%",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            mb: 2,
          }}
        >
          <Stack minWidth="600px" marginTop={3}>
            <ReactECharts
              option={getOption(incomes, filters, amount)}
              style={{ height: "400px", width: "100%" }}
            />
          </Stack>
        </Box>
        <Stack width="100%">
          <ReactECharts option={option} style={{ height: "400px" }} />
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalNewBillGeneral
        openModalNewBill={openModalNewBill}
        handleCloseModal={handleCloseModal}
        centers={centersfiltred}
        mainDataNewBill={mainDataNewBill}
        setMainDataNewBill={setMainDataNewBill}
        SubmitmodalBill={SubmitmodalBill}
        loadApi={loadApi}
        newBillError={newBillError}
        showAdditionalFields={showAdditionalFields}
        setShowAdditionalFields={setShowAdditionalFields}
        mainDataNewRate={mainDataNewRate}
        setMainDataNewRate={setMainDataNewRate}
        mainDataNewRateError={mainDataNewRateError}
        clients={clients}
        occupation={occupation}
      />
    </>
  );
}
