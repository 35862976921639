import GroupsIcon from "@mui/icons-material/Groups";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LogoutIcon from "@mui/icons-material/Logout";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const globalOptions = [];

const headerOptions = {
  logged: [
    {
      title: "Agenda",
      icon: HolidayVillageIcon,
      route: "/calendar",
      roles: ["superadmin", "admin", "user", "subUser"],
    },
    {
      title: "Pacientes",
      icon: GroupsIcon,
      route: "/clients",
      roles: ["superadmin", "admin", "user", "subUser"],
    },
    {
      title: "Cobros",
      icon: BarChartIcon,
      route: "/income",
      roles: ["superadmin", "admin", "user", "subUser"],
    },
    {
      title: "Reportes",
      icon: BarChartIcon,
      route: "/reports",
      roles: ["superadmin", "admin", "user", "subUser"],
    },
    {
      title: "Perfil",
      icon: AccountCircleIcon,
      route: "/profile",
      roles: ["superadmin", "admin", "user", "subUser"],
    },
    {
      title: "Cerrar Sesión",
      route: "/logout",
      icon: LogoutIcon,
    },
  ],
  default: [...globalOptions],
};

export default headerOptions;
