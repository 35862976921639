export const fetchOccupation = async (loadApi, showSnack, setOccupation) => {
  try {
    const { data } = await loadApi("profile/occupation", true, "get");

    setOccupation(data);
  } catch (error) {
    showSnack(error?.message || "Error al obtener ocupación");
    setOccupation("Clientes");
  }
};
