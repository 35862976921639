import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SnackAlert from "../../components/SnackAlert";
import GlobalHeader from "../../components/header";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import ModalCommunications from "./modalCommunications";
import ModalClient from "./modalclient";
import Modaltreatments from "./modaltreatments";
import capitalizarCadena from "../../utils/Cadena";
import DateCusmton from "../../utils/DateCusmton";
import { fetchOccupation } from "../../utils/profesion";
import { LoadUserModal } from "./loadUsers";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";

export default function Clients() {
  const navigate = useNavigate();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi } = useApi();
  const [openbackd, setOpenbackd] = useState(false);
  const [photo_profile, setPhoto_profile] = useState();
  const [occupation, setOccupation] = useState("");

  const initialMainData = {
    center_id: "",
    name: "",
    lastname: "",
    national_id: "",
    email: "",
    phone: "",
    address: "",
    note: "",
  };
  const [mainData, setMainData] = useState(initialMainData);
  const [mainDataError, setMainDataError] = useState({
    center_id: false,
    name: false,
    lastname: false,
    national_id: false,
    email: false,
    phone: false,
    address: false,
    note: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalCom, setOpenModalCom] = useState(false);
  const [openModalTret, setOpenModalTret] = useState(false);
  const [centersfiltred, setCentersfiltred] = useState();

  useEffect(() => {
    loadApi("center/centers_on", true, "get")
      .then((response) => {
        setCentersfiltred(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
    fetchOccupation(loadApi, showSnack, setOccupation);
  }, []);
  const [searchText, setSearchText] = useState({
    searchText: "",
  });

  const [mainDataModalComError, setMainDataModalComError] = useState({
    channel: false,
    text: false,
  });
  const [mainDataModalCom, setMainDataModalCom] = useState({
    channel: "",
    text: "",
  });
  useEffect(() => {
    setMainData(initialMainData);
  }, [openModal]);

  const [clients, setClients] = useState([]);
  useEffect(() => {
    loadApi("client/clients", true, "get")
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  }, []);
  useEffect(() => {
    setOpenbackd(true);
    loadApi("profile", true, "get")
      .then((response) => {
        setPhoto_profile(response.data.photo_profile.url);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenbackd(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  }, []);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setMainDataError({
      center_id: false,
      name: false,
      lastname: false,
      national_id: false,
      email: false,
      phone: false,
      address: false,
      note: false,
    });
  };
  const handleCloseModalTret = () => {
    setOpenModalTret(false);
  };

  const handleCloseModalCom = () => {
    setOpenModalCom(false);
    setMainDataModalCom({
      channel: "",
      text: "",
    });
  };
  const Submit = async (e) => {
    e.preventDefault();

    if (!Validate(mainData, setMainDataError)) return;
    handleCloseModal();
    setOpenbackd(true);
    loadApi("client", true, "post", {
      ...mainData,
    })
      .then((response) => {
        showSnack(response.data.message, "success", 2000);

        setClients((adata) => [...adata, response.data.Client]);
      })
      .catch((e) => {
        showSnack(e.message);
        setOpenModal(false);
      })
      .finally((e) => {
        setOpenbackd(false);
      });
  };
  const filteredClients = clients.filter((client) => {
    const searchTextLower = searchText.searchText.toLowerCase();
    const clientNameLower = client?.name.toLowerCase();
    const centerNameLower = client.Center?.name.toLowerCase();
    const clientLastNameLower = client?.lastname.toLowerCase();

    return (
      clientNameLower.includes(searchTextLower) ||
      centerNameLower.includes(searchTextLower) ||
      clientLastNameLower.includes(searchTextLower)
    );
  });
  const SubmitModalCommunications = () => {
    if (!ValidateModalCom(mainDataModalCom, setMainDataModalComError)) return;
    handleCloseModalCom();
    if (mainDataModalCom.channel === "Email") {
      setOpenbackd(true);
      loadApi(
        `externalChats/sendEmail/${openModalCom.id}/${openModalCom.center_id}`,
        true,
        "post",
        {
          ...mainDataModalCom,
        }
      )
        .then((response) => {
          showSnack(response.data.message, "success", 2000);
          setMainDataModalComError({
            channel: false,
            text: false,
          });
        })
        .catch((e) => {
          showSnack(e.message);
          setOpenbackd(false);
        })
        .finally((e) => {
          setOpenbackd(false);
        });
    } else {
      showSnack("El servicio aún no está habilitado", "error");
    }
  };

  const handleDownloadFormat = () => {
    // Define los encabezados y una fila de ejemplo vacía
    const headers = [
      "centro",
      "nombre",
      "apellidos",
      "documento",
      "Correo electronico",
      "telefono",
      "direccion",
      "nota",
    ];

    // Crear una fila vacía con los encabezados
    const data = [
      headers.reduce((acc, header) => {
        acc[header] = ""; // Cada celda está vacía
        return acc;
      }, {}),
    ];

    // Crear una nueva hoja de trabajo
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // Crear un nuevo libro de trabajo y agregar la hoja de trabajo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Formato");

    // Generar el archivo XLSX y desencadenar la descarga
    XLSX.writeFile(wb, "formato.xlsx");
  };

  return (
    <>
      <GlobalHeader LogoProfile={photo_profile} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackd}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", background: "#FFFFFF", p: 2 }}
      >
        <Grid container direction="row" sx={{ mb: 2 }}>
          {/* First Grid Item: Typography and Search Field */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, color: "black", mt: 3 }}
            >
              {occupation}
            </Typography>
            <Box display="flex" justifyContent="flex-start" marginY={2}>
              <TextField
                id="outlined-basic"
                // Eliminar el label
                label=""
                placeholder="Buscar"
                variant="outlined"
                value={searchText.searchText}
                onChange={(event) =>
                  setSearchText((adata) => ({
                    ...adata,
                    searchText: event.target.value,
                  }))
                }
                InputProps={{
                  sx: {
                    borderRadius: 3,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

          {/* Second Grid Item: Button and Modal */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              variant="contained"
              disableElevation
              onClick={handleClickOpenModal}
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
            >
              <AddOutlinedIcon sx={{ marginInlineEnd: 1 }} />
              <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
                Nuevo {occupation}
              </Typography>
            </Button>

            <LoadUserModal
              loadApi={loadApi}
              occupation={occupation}
              showSnack={showSnack}
            />

            <Button
              variant="contained"
              disableElevation
              onClick={handleDownloadFormat}
              gap={1}
              sx={{
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                boxShadow:
                  "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                ":hover": {
                  bgcolor: "#ebebeb",
                  boxShadow:
                    "rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px",
                },
              }}
            >
              <SiMicrosoftexcel color="green" size={20} />
              <Typography variant="p1" sx={{ fontWeight: 600, color: "black" }}>
                Formato
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ marginBottom: 2 }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{occupation} </TableCell>
                <TableCell align="center"> Ultima Consulta</TableCell>
                <TableCell align="center">Cantidad de Consultas</TableCell>
                <TableCell align="center">Cobros Pendientes</TableCell>
                <TableCell align="center">Consultas Agendadas</TableCell>
                <TableCell align="center">Centro</TableCell>
                <TableCell align="center">Tratamiento Ralizado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {filteredClients &&
                (filteredClients || []).map((row, index) => (
                  <TableRow
                    onClick={() => navigate(`/client/${row.id}`)}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" scope="row">
                      {capitalizarCadena(row.name + " " + row.lastname)}
                    </TableCell>
                    <TableCell align="center">
                      {row.Calendars.lastConsultationDate !== null
                        ? DateCusmton(row.Calendars.lastConsultationDate)
                        : "Sin consultas anteriores"}
                    </TableCell>
                    <TableCell align="center">
                      {row.Calendars.totalConsultations !== 0
                        ? row.Calendars.totalConsultations
                        : "Sin consultas "}
                    </TableCell>
                    <TableCell align="center">
                      {row.pendingInvoicesCount !== 0
                        ? row.pendingInvoicesCount
                        : "Sin facturas pendientes "}
                    </TableCell>
                    <TableCell align="center">
                      {row.Calendars.upcomingConsultations !== 0
                        ? row.Calendars.upcomingConsultations
                        : "Sin consultas agendadas "}
                    </TableCell>
                    <TableCell align="center">
                      {capitalizarCadena(
                        !row?.Center?.name ? "Sin Centro" : row?.Center?.name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenModalTret({
                            invoices: row.invoices,
                          });
                        }}
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredClients?.length < 1 && (
          <Stack mt={2}>
            <Alert severity="warning">Aún no tiene {occupation}.</Alert>
          </Stack>
        )}
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <ModalClient
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        mainData={mainData}
        setMainData={setMainData}
        centers={centersfiltred}
        Submit={Submit}
        mainDataError={mainDataError}
        occupation={occupation}
      />
      <ModalCommunications
        openModalCom={openModalCom}
        setMainDataModalCom={setMainDataModalCom}
        mainDataModalCom={mainDataModalCom}
        handleCloseModalCom={handleCloseModalCom}
        SubmitModalCommunications={SubmitModalCommunications}
        mainDataModalComError={mainDataModalComError}
        setMainDataModalComError={setMainDataModalComError}
      />
      <Modaltreatments
        openModalTret={openModalTret}
        handleCloseModalTret={handleCloseModalTret}
      />
    </>
  );
}

function Validate(mainData, setMainDataError) {
  var final = false;
  const newAdata = {
    center_id: !mainData.center_id,
    national_id: !mainData.national_id,
    name: !mainData.name,
    lastname: !mainData.lastname,
  };

  setMainDataError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
function ValidateModalCom(mainDataModalCom, setMainDataModalComError) {
  var final = false;
  const newAdata = {
    channel: !mainDataModalCom.channel,
    text: !mainDataModalCom.text,
  };

  setMainDataModalComError(newAdata);

  for (let key in newAdata) {
    if (!!newAdata[key]) {
      final = true;
      break;
    }
  }

  return !final;
}
